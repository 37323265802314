/* eslint-disable quotes */
export default {
  general: {
    welcome: 'Bienvenue !',
    sitescount: 'Nombre de sites',
    pagescount: 'Nombre de pages',
    changescount: 'Nombre de détections',
    close: "Fermer",
    description: "Description",
    code: "Code",
    nom: "Nom",
    libelle: "Libelle",
    title: "Titre",
    type: "Type",
    search: "Chercher",
    add: "Ajouter",
    export: "Exporter",
    id: "ID",
    date: "Date",
    author: "Auteur",
    status: "Statut",
    actions: "Actions",
    edit: "Modifier",
    delete: "Supprimer",
    cancel: "Annuler",
    confirm: "Confirmer",
    keyword: "Mots clés",
    role: "Role",
    permissions: "Permissions",
    menus: "Menus",
    rolesEtpermissions: "Rôles et permissions",
    deleteWarningText: "Êtes-vous sûr de vouloir supprimer ?",
    deleteConfirmButtonText: "Oui supprimer",
    deleteCancelButtonText: "Non laisser",
    save: "Sauvegarder",
    saving: "Sauvegarde ...",
    closingWarningText: "Êtes-vous sûr de vouloir fermer ?",
    notFound: "Aucun élément trouvé",
    actives: "Actives",
    visible: "Visible",
    disponibles: "Disponibles",
    M_actives: "Activés",
    F_actives: "Activées",
    M_desactives: "Désactivés",
    F_desactives: "Désactivées",
    M_supprimes: "Supprimés",
    F_supprimes: "Supprimées",
    corbeille: "Corbeille",
    restaurer: "Restaurer",
    suppressionDefinitive: "Suppression définitive",
    nonActives: "Non Actives",
    nonVisibles: "Non Visibles",
    F_actif: "Active",
    M_actif: "Actif",
    deleteContinue: "Continuer ?",
    suppressionAnnule: "Suppression annulée",
    filter: "Filtrer",
    init: "Réinitialiser",
    statusUpdated: "Mise à jour du status éffectuée avec succès !",
    statusUpdateError:
      "Désolé, une erreur s'est produite lors de la mise à jour du status",
    ouiSupprimer: "Oui supprimer",
    irrversibleMessage: "Cette action est irréversible",
    SureQuestion: "Etes-vous sûre ?",
    periode: "Période",
    startDateplaceholder: "Date de début",
    endDateplaceholder: "Date de fin",
    DaterangeSeparator: "à",
    ImapeUploadFieldText: "Cliquer pour envoyer",
    clotures: "Clôturés",
    labelColonneCloturer: "clôturée",
    allpermissions: "Toutes les permissions",
    formInvalid:
      "Votre formulaire n'est pas valide ! Veuillez vérifier les données !",
    admins: "Admins",
    donors: "Donors",
    recipients: "Recipients",
    others: "Autres",
    processing: "Processing",
    success: "Success",
    error: "Error",
    disabled: "Disabled",
    enabled: "Enabled",
    details: "Détails",
    yes: "Yes",
    no: "No",
    paredExchange: "Paired exchange",
    guest: "Invités",
    editors: "Editeurs",
    othersUsers: "Autres utilisateurs",
    usersAndSubscriptions: "Utilisateurs & Membres",
    publish: "Publier",
    SaveDraft: "Enregistrer comme brouillon",
    envoiEncours: "Envoi en cours...",
    envoyer: "Envoyer",
    sendGeneralNotification: "Notifier tous",
    invalidForm: "Veuillez revérifier les informations saisies",
    accountIsActive: "Votre compte est actif !",
    accountIsActiveDetails:
      "Votre compte est bien actif, vous ne disposez pas encore de permission d'accès à Deface Tracker. Contactez votre administrateur",
    at: "à",
    elligible: "Éligible",
    refresh: "Actualiser",
  },
  route: {
    dashboard: "Accueil",
    introduction: "Introduction",
    documentation: "Documentation",
    guide: "Guide",
    permission: "Permission",
    pagePermission: "Page de Permission",
    rolePermission: "Role et Permission",
    icons: "Icones",
    components: "Composants",
    componentIndex: "Introduction",
    errorPages: "Pages d'erreur",
    page401: "401",
    page404: "404",
    errorLog: "Log",
    administration: "Administration",
    users: "Utilisateurs",
    userProfile: "Profile Utilisateur",
    sites: "Les Sites",
    UserProfile: "Profile",
    sitePage: "Pages",
    monitoring: "Surveillance",
    tracker: 'Traqueur',
    sitesListe: 'Sites et pages',
  },
  navbar: {
    logOut: "Déconnexion",
    dashboard: "Accueil",
    github: "Github",
    theme: "Theme",
    size: "Taille globale",
    profile: "Profile",
  },
  login: {
    title: "Se connecter",
    logIn: "Connecté",
    username: "Identifiant",
    password: "Mot de passe",
    email: "Adresse email",
    correctEmail: "Entrez une adresse email correcte",
    shortPassword: "Le mot de passe est trop court (minimum 6 lettres)",
    emailOrUsername: "Identifiant",
    emailOrUsernameRequired:
      "Veuillez saisir votre nom d'utilisateur ou e-mail",
  },
  passwordreset: {
    enterCode: "Entrez le code envoyé par mail",
    checkCode: "Vérifier",
    token: "Code",
    forgotPassword: "Mot de passe oublié",
    resendcode: "Renvoyer le code",
    enterEmail: "Entrez votre adresse e-mail",
    sendcode: "Envoyer le code",
    setYourPassword: "Définissez votre nouveau mot de passe",
    confirmPassword: "Confirmez votre mot de passe",
    password: "Entrez votre mot de passe",
    savepassword: "Enregistrer",
    ConfirmPasswordRequired: "Confirmez votre mot de passe",
    codeIsRequired: "Veuillez saisir le code",
    successEnd: "Votre mot de passe a été changé !",
    successEndDetails:
      "Vous avez changé votre mot de passe avec succès ! vous pouvez maintenant vous connecter avec votre nouveau mot de passe",
    codeIsValid: "Code valide ! veuillez entrer votre nouveau mot de passe",
    codeSent: "Un code vous a été envoyé par mail, merci de vérifier",
  },
  permission: {
    addRole: "Nouveau Role",
    editPermission: "Modifier la Permission",
    editPermissions: "Modifier les Permissions",
    roles: "Roles",
    switchRoles: "Activer les roles",
    delete: "Supprimer",
    confirm: "Confirmer",
    cancel: "Annuler",
    permissionsUpdated: "Les permissions ont bien été mises à jour !",
    extraPermissions: "Permissions supplémentaires",
    addedPermissions: "Permissions ajoutées",
    noSelectionAdd: "Aucune permission ajoutée",
    manage: "Gérer les permissions",
    addPermissions: "Ajouter des permissions",
    tableName: "Nom de la table",
    NameRequired: "Veuillez saisir le nom",
    iscreatedSuccessfully: "Permission ajoutée avec succès",
    name: "Permission",
    suppressionEffectue: "Permission supprimée avec succès",
  },
  excel: {
    export: "Exporter",
    selectedExport: "Exporter les éléments sélectionnés",
    placeholder:
      "Entrez le nom du fichier s'il vous plaît (par défaut : liste-excel)",
  },
  theme: {
    change: "Changer le Theme",
    documentation: "documentation du Theme",
    tips: "Conseils: Il est différent du choix de thème sur la barre de navigation, ce sont deux méthodes de skinning différentes, chacune avec des scénarios d'application différents.",
  },
  settings: {
    title: "Configuration du style de Page",
    theme: "Couleur du Theme",
    tagsView: "Afficher les tags",
    fixedHeader: "Fixer la d'entête",
    sidebarLogo: "Logo",
  },
  tagsView: {
    refresh: "Rafraîchir",
    close: "Fermer",
    closeOthers: "Fermer les autres",
    closeAll: "Fermer tout",
  },
  user: {
    name: "Name",
    user: "Utilisateur",
    role: "Role",
    password: "Mot de passe",
    c_password: "Confirmer",
    nom: "Nom",
    prenom: "Prenom",
    email: "Adresse Email",
    username: "Identifiant",
    code: "Code",
    EditPermissions: "Modifier les permissions",
    AddUser: "Ajouter un utilisateur",
    selectRole: "Sélectionnez un rôle",
    PasswordsInconform: "Les mots de passes ne sont pas conformes",
    RoleRequired: "Veuillez sélectionner un rôle",
    NomRequired: "Veuillez saisir le nom",
    EmailRequired: "Veuillez saisir le mail",
    EmailInvalide: "Veuillez saisir une adresse email valide",
    PasswordRequired: "Veuillez entrer le mot de passe",
    deleteWarn: "Ceci supprimera l'utilisateur",
    deleteContinue: "Continuer ?",
    cancel: "Annuler",
    suppressionEffectue: "Suppression effectuée avec succès!",
    suppressionAnnule: "Suppression annulée",
    NewUser: "Nouveau utilisateur",
    iscreatedSuccessfully: "a été crée avec succès",
    PermissionsUpdated: "Permissions mise à jour avec succès!",
    users: "Utilisateurs",
    edit: "Modifier l'utilisateur",
    PasswordMin6: "Doit avoir au moins 6 caractères",
    isupdatedSuccessfully:
      "Mise à jour de l'utilisateur éffectuée avec succès !",
    changeCredentils: "Modifier le mot de passe",
    nochangeCredentils: "Ne pas modifier le mot de passe",
    changeAvatar: "Changer l'avatar",
    account: "Compte",
    enterPassword: "Entrez votre mots de passe pour continuer",
    profileUpdated: "Votre profile a bien été mise à jour !",
    willAddPassword: "L'utilisateur ajoutera son mot de passe",
    willAddPasswordMessage:
      "Un mail sera envoyé à l'utilisateur sur l'adresse email reinseigné avec un lien pour intégrer son mot de passe",
  },
  roles: {
    deleteWarn: "Attention, ceci supprimera le role",
    permissions: "Permissions",
    nom: "Nom du rôle",
    users: "Utilisateurs",
    extraMenus: "Menus supplémentaires",
    InheritedfromRole: "Hérité du rôle",
    addRole: "Ajouter un rôle",
    name: "Role",
    NewRole: "Nouveau rôle",
    iscreatedSuccessfully: "crée avec succès!",
    isupdatedSuccessfully: "Le rôle a été mis à jour avec succès",
    NameRequired: "Veillez saisir le rôle",
    edit: "Modifier le rôle",
    suppressionEffectue: "Rôle supprimé avec succès",
    description: {
      admin: "Le Super Administrateur administre l'application",
      manager: "Le Manager est le gérant de l'application",
      editeur: "Utilisateur pouvant intégrer des données",
      utilisateur: "Utilisateur normal",
      visiteur: "les visiteurs ont juste un droit de visualisation",
    },
  },
  site: {
    site: "Site",
    sites: "Les sites",
    url: "Lien",
    title: "Titre",
    UrlRequired: "Veuillez saisir le lien du site",
    BodyRequired: "Ajoutez un commentaire !",
    deleteWarn: "Ceci supprimera le site",
    suppressionEffectue: "Site supprimé avec succès",
    NewSite: "Nouveau Site",
    iscreatedSuccessfully: "crée avec succès",
    isupdatedSuccessfully: "Mise à jour du site éffectuée avec succès !",
    created_at: "Crée le",
    edit: "Modifier le site",
    AddSite: "Nouveau site",
    UserRequired: "Vous devez sélectionner un utilisateur",
    selectSiteTitle: "Sélectionnez le site",
    adminEmail: "Admin Email",
    pagecount: "Pages",
    validAdminEmail: "Veuillez entrer une adresse e-mail valide",
    versions: "Versions",
    lastCheck: "Dernière vérification",
    noPage: "Aucune page trouvée",
    createdAt: "Date",
    VersionNumber: "Version numéro",
    notifyAdmin: "Notifier l'administrateur",
    sendNotificaction: "Envoyer une notification",
  },
  message: {
    message: "Message",
    messages: "Messages",
    subject: "Sujet",
    description: "Description",
    subjectRequired: "Entrez le sujet",
    messageRequired: "Entrez le message",
    deleteWarn: "Le message sera supprimé",
    suppressionEffectue: "Message supprimé avec succès",
    NewCategorie: "Nouveau message",
    iscreatedSuccessfully: "crée avec succès",
    sentMessage: "Votre message a bien été envoyé",
    isupdatedSuccessfully: "Message mis à jour avec succès",
    created_at: "Crée le",
    edit: "Modifier le message",
    AddCategorie: "Nouveau message",
    sendTo: "Envoyer à ",
  },
};
