/* eslint-disable quotes */
/** On peut décomposer les routes par module**/
import Layout from "@/layout";

const adminRoutes = {
  path: "/administration",
  component: Layout,
  redirect: "/administration/users",
  name: "Administration",
  // alwaysShow: true,
  meta: {
    title: "administration",
    icon: "el-icon-setting",
    permissions: ["manage user", "manage permission"],
  },
  children: [
    {
      path: "users",
      component: () =>
        import(/* webpackChunkName: "users" */ "@/views/users/index"),
      name: "UserList",
      meta: {
        title: "users",
        icon: "el-icon-user-solid",
        permissions: ["manage user"],
      },
    },
    /** Roles et permissions */
    {
      path: "roles",
      component: () =>
        import(/* webpackChunkName: "roles" */ "@/views/role-permission/index"),
      name: "RoleList",
      meta: {
        title: "rolePermission",
        icon: "el-icon-lock",
        permissions: ["manage permission"],
      },
    },
  ],
};

export default adminRoutes;
