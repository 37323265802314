/* eslint-disable quotes */
export default {
  general: {
    welcome: 'Welcome !',
    pagescount: 'Number of pages',
    changescount: 'Changes',
    sitescount: 'Number of sites',
    close: "Close",
    description: "Description",
    code: "Code",
    nom: "Name",
    libelle: "Label",
    title: "Title",
    type: "Type",
    search: "Search",
    add: "Add",
    export: "Export",
    id: "ID",
    date: "Date",
    author: "Author",
    status: "Status",
    actions: "Actions",
    edit: "Edit",
    delete: "Delete",
    cancel: "Cancel",
    confirm: "Confirm",
    keyword: "Keywords",
    role: "Role",
    permissions: "Permissions",
    menus: "Menus",
    rolesEtpermissions: "Roles and permissions",
    deleteWarningText: "Are you sure you want to delete?",
    deleteConfirmButtonText: "Yes delete",
    deleteCancelButtonText: "No leave",
    save: "Save",
    saving: "Saving ...",
    closingWarningText: "Are you sure you want to close?",
    notFound: "No item found",
    actives: "Actives",
    visible: "Visible",
    disponibles: "Available",
    M_actives: "Enabled",
    F_actives: "Enabled",
    M_desactives: "Deactivated",
    F_desactives: "Deactivated",
    M_supprimes: "Deleted",
    F_supprimes: "Deleted",
    corbeille: "Trash",
    restaurer: "Restore",
    suppressionDefinitive: "Definitive deletion",
    nonActives: "Not active",
    nonVisibles: "Not visible",
    F_actif: "Active",
    M_actif: "Active",
    deleteContinue: "Continue ?",
    suppressionAnnule: "Deletion canceled",
    filter: "Filter",
    init: "Reset",
    statusUpdated: "Status update successfully completed!",
    statusUpdateError: "Sorry, an error occurred while updating the status",
    ouiSupprimer: "Yes delete",
    irrversibleMessage: "This action is irreversible",
    SureQuestion: "Are you sure ?",
    periode: "Period",
    startDateplaceholder: "Start date",
    endDateplaceholder: "End date",
    DaterangeSeparator: "to",
    ImapeUploadFieldText: "Click to send",
    clotures: "Closed",
    allpermissions: "All permissions",
    admins: "Admins",
    donors: "Donors",
    recipients: "Recipients",
    others: "Others",
    processing: "Processing",
    success: "Success",
    error: "Error",
    disabled: "Disabled",
    enabled: "Enabled",
    details: "Details",
    yes: "Yes",
    no: "No",
    paredExchange: "Paired exchange",
    paired: "Paired",
    guest: "Guests",
    editors: "Editors",
    othersUsers: "Other users",
    usersAndSubscriptions: "Users & Members",
    publish: "Publish",
    SaveDraft: "Save as a draft",
    envoiEncours: "Sending in progress...",
    envoyer: "Send",
    sendGeneralNotification: "Notify them all",
    invalidForm: "Please check the information entered",
    accountIsActive: "Your account is active!",
    accountIsActiveDetails:
      "Your account is active, you do not yet have permission to access Deface Tracker. Contact your administrator",
    at: "at",
    refresh: "Refresh",
    elligible: "Eligible",
  },
  route: {
    dashboard: "Home",
    introduction: "Introduction",
    documentation: "Documentation",
    guide: "Guide",
    permission: "Permission",
    pagePermission: "Permission page",
    rolePermission: "Role and Permission",
    icons: "Icons",
    components: "Components",
    componentIndex: "Introduction",
    errorPages: "Error Pages",
    page401: "401",
    page404: "404",
    errorLog: "Log",
    administration: "Administration",
    users: "Users",
    userProfile: "User profile",
    sites: "Sites",
    UserProfile: "Profile",
    sitePage: "Pages",
    monitoring: "Monitoring",
    tracker: 'Tracker',
    sitesListe: 'Sites and pages',
  },
  navbar: {
    logOut: "Logout",
    dashboard: "Home",
    github: "Github",
    size: "Globale size",
    profile: "Profile",
  },
  login: {
    title: "Sign in",
    logIn: "Loged in",
    username: "Username",
    password: "Password",
    email: "Email address",
    correctEmail: "Please enter the correct email",
    shortPassword: "Password cannot be less than 6 digits",
    emailOrUsername: "Email address or Username",
    emailOrUsernameRequired: "Please enter your email address or username",
  },
  passwordreset: {
    enterCode: "Entrez le code envoyé par mail",
    checkCode: "Vérifier",
    token: "Code",
    forgotPassword: "I forgot my password",
    resendcode: "Return the code",
    enterEmail: "Enter your e-mail adress",
    sendcode: "Send code",
    setYourPassword: "Set your new password",
    confirmPassword: "Confirm your password",
    password: "Enter your password",
    savepassword: "Save",
    ConfirmPasswordRequired: "Confirm your password",
    codeIsRequired: "Please enter the code",
    successEnd: "Your password has been changed!",
    successEndDetails:
      "You have successfully changed your password! you can now log in with your new password",
    codeIsValid: "Code valid! please enter your new password",
    codeSent: "A code has been sent to you by email, please check",
  },
  permission: {
    addRole: "New Role",
    editPermission: "Edit Permission",
    editPermissions: "Edit Permissions",
    roles: "Roles",
    switchRoles: "Activate roles",
    delete: "Delete",
    confirm: "Confirm",
    cancel: "Cancel",
    permissionsUpdated: "The permissions have been updated!",
    extraPermissions: "Additional permissions",
    addedPermissions: "Added permissions",
    noSelectionAdd: "No permissions added",
    manage: "Manage permissions",
    addPermissions: "Add permissions",
    tableName: "Table name",
    NameRequired: "Please enter name",
    iscreatedSuccessfully: "Permission added successfully",
    name: "Permission",
    suppressionEffectue: "Permission successfully deleted",
  },
  excel: {
    export: "Export",
    selectedExport: "Export selected items",
    placeholder: "Enter the file name please (default: list-excel)",
  },
  theme: {
    change: "Change the Theme",
    documentation: "Theme documentation",
    tips: "Tips: It is different from the theme choice on the navigation bar, it is two different skinning methods, each with different application scenarios.",
  },
  settings: {
    title: "Page style configuration",
    theme: "Theme color",
    tagsView: "Show tags",
    fixedHeader: "Fix the header",
    sidebarLogo: "Logo",
  },
  tagsView: {
    refresh: "Refresh",
    close: "Close",
    closeOthers: "Close others",
    closeAll: "CLose all",
  },
  user: {
    name: "Name",
    user: "User",
    role: "Role",
    password: "Password",
    c_password: "Confirm",
    nom: "Name",
    prenom: "First name",
    email: "Email address",
    username: "Username",
    code: "Code",
    EditPermissions: "Edit permissions",
    AddUser: "Add user",
    selectRole: "Select a role",
    PasswordsInconform: "Passwords do not match",
    RoleRequired: "Please select a role",
    NomRequired: "Enter the name",
    EmailRequired: "Enter the email address",
    EmailInvalide: "Enter a valid email address",
    PasswordRequired: "Enter the password",
    deleteWarn: "This will delete the user",
    deleteContinue: "Continue ?",
    cancel: "Cancel",
    suppressionEffectue: "Deletion completed successfully!",
    suppressionAnnule: "Deletion canceled",
    NewUser: "New user",
    iscreatedSuccessfully: " was successfully created",
    PermissionsUpdated: "Permissions updated successfully!",
    users: "Users",
    edit: "Edit user",
    PasswordMin6: "Must be at least 6 characters",
    isupdatedSuccessfully: "User updated successfully",
    changeCredentils: "Change credentials",
    nochangeCredentils: "Do not change the password",
    changeAvatar: "Upload",
    account: "Account",
    enterPassword: "Enter your password to continue",
    profileUpdated: "Your profile has been updated !",
    willAddPassword: "The user will add his own password",
    willAddPasswordMessage:
      "An email will be sent to the user on the email address provided with a link to set his password",
  },
  roles: {
    deleteWarn: "Be careful, this will remove the role",
    permissions: "Permissions",
    nom: "Role name",
    users: "Users",
    extraMenus: "Additional menus",
    InheritedfromRole: "Inherited from the role",
    addRole: "Add a role",
    name: "Role",
    NewRole: "New role",
    iscreatedSuccessfully: "created successfully!",
    isupdatedSuccessfully: "Role updated successfully",
    NameRequired: "Please enter the role",
    edit: "Edit the role",
    suppressionEffectue: "Role deleted successfully",
  },
  site: {
    site: "Site",
    sites: "Sites",
    url: "Url",
    title: "Title",
    UrlRequired: "Please enter the site url",
    BodyRequired: "Add a comment!",
    deleteWarn: "THis will delete the site",
    suppressionEffectue: "Site deleted successfully !",
    NewSite: "New Site",
    iscreatedSuccessfully: "created successfully",
    isupdatedSuccessfully: "Site update successfully completed!",
    created_at: "Created at",
    edit: "Edit the site",
    AddSite: "New site",
    UserRequired: "You must select a user",
    selectSiteTitle: "Select the site",
    adminEmail: "Admin Email",
    pagecount: "Pages",
    validAdminEmail: "Please enter a valid email addres",
    versions: "Versions",
    noPage: "No page found",
    lastCheck: "Last Check",
    createdAt: "Date",
    VersionNumber: "Version number",
    notifyAdmin: "Notify the admin",
    sendNotificaction: "Send a notification",
  },
  message: {
    message: "Message",
    messages: "Messages",
    subject: "Subject",
    description: "Description",
    subjectRequired: "Please enter the subject",
    messageRequired: "Please enter the message",
    deleteWarn: "This will remove the message",
    suppressionEffectue: "message successfully deleted",
    NewCategorie: "New Message",
    iscreatedSuccessfully: "successfully created",
    sentMessage: "Your message has been sent successfully",
    isupdatedSuccessfully: "Message update successfully completed!",
    created_at: "Created at",
    edit: "Edit message",
    AddCategorie: "New message",
    sendTo: "Send to ",
  },
};
